<template>
  <v-snackbar
    v-if="currentMessage"
    :value="showSnackbar"
    :color="currentMessage.color"
    right
    bottom
  >
    {{ currentMessage.text }}
  </v-snackbar>
</template>

<script lang="ts">
import eventBus from "@/application/eventBus";
import Vue from "vue";

interface SnackbarMessage {
  text: string;
  color: string;
}

interface ComponentData {
  messages: SnackbarMessage[];
  hideTimeout: number;
  showSnackbar: boolean;
}

export default Vue.extend({
  name: "TheSnackbarQueueHandler",
  data: (): ComponentData => ({
    messages: [],
    hideTimeout: 0,
    showSnackbar: false,
  }),
  computed: {
    currentMessage(): SnackbarMessage | null {
      if (!this.messages.length) return null;
      return this.messages[0];
    },
  },
  created(): void {
    eventBus.$on("snackbar", (snackbar: SnackbarMessage | string) => {
      if (typeof snackbar === "string") {
        this.messages.push({
          text: snackbar,
          color: "success",
        });
      } else {
        this.messages.push(snackbar);
      }
      this.handleNextMessage();
    });
  },
  destroyed(): void {
    clearTimeout(this.hideTimeout);
  },
  methods: {
    handleNextMessage(): void {
      if (this.showSnackbar) return; //already showing an message
      if (!this.messages.length) return; // no more messages to show

      this.showSnackbar = true;

      this.hideTimeout = setTimeout(() => {
        this.showSnackbar = false;
        setTimeout(() => {
          this.messages.splice(0, 1);
          this.handleNextMessage();
        }, 500);
      }, 5000);
    },
  },
});
</script>
