import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./application/store";
import vuetify from "./plugins/vuetify";
import i18n from "@/plugins/i18n";
import * as Sentry from "@sentry/vue";
import "./scss/main.scss";
import "./scss/overrides.scss";
import { environment } from "@/application/api/baseUrl";

Vue.config.productionTip = false;

if (
  "development" !== process.env.NODE_ENV &&
  window.location.hostname !== "localhost" &&
  window.location.hostname !== "127.0.0.1"
) {
  Sentry.init({
    Vue,
    dsn: "https://ce4678279e4d45209067fecfbd112568@o88312.ingest.sentry.io/6141818",
    environment: environment(),
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
  });
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
