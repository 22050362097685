import { destroy, get, post } from "@/application/api";
import { AxiosResponse } from "axios";

export interface Photo {
  id: number;
  isConverted: boolean;
  name: string;
  type: string;
  url: string;
}

export interface PhotoCategory {
  type: string;
  photos: Photo[];
}

interface showResponse {
  data: PhotoCategory[];
}

export const photos = async (
  objectionRequestId: number
): Promise<AxiosResponse<showResponse>> => {
  return await get(`objection-request/${objectionRequestId}/photo`);
};

interface createRequest {
  objectionRequestId: number;
  type: unknown; //@TODO
  files: number[];
}
export const createPhotos = (
  data: createRequest
): Promise<AxiosResponse<void>> => {
  return post(`objection-request/${data.objectionRequestId}/photo`, {
    type: data.type,
    files: data.files,
  });
};

export const deletePhoto = (photoId: number): Promise<AxiosResponse<void>> => {
  return destroy(`photo/${photoId}`);
};
