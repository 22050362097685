import { RouteConfig } from "vue-router";
import Authorisation from "@/templates/Authorisation/Authorisation.vue";
import Login from "@/modules/authorisation/views/Login.vue";
import RedirectToDashboardIfLoggedInGuard from "@/router/guards/RedirectToDashboardIfLoggedInGuard";

const authorisationRoutes: RouteConfig = {
  path: "",
  redirect: { name: "login" },
  component: Authorisation,
  children: [
    {
      path: "login",
      name: "login",
      component: Login,
      beforeEnter: RedirectToDashboardIfLoggedInGuard,
    },
    {
      path: "auth/callback",
      name: "auth.callback",
      component: () =>
        import("@/modules/authorisation/views/AuthorisationCallback.vue"),
    },
    {
      path: "password/forgotten",
      name: "password.forgotten",
      component: () =>
        import("@/modules/authorisation/views/PasswordForgotten.vue"),
      beforeEnter: RedirectToDashboardIfLoggedInGuard,
    },
    {
      path: "password/reset/:token",
      name: "password.reset",
      component: () =>
        import("@/modules/authorisation/views/PasswordReset.vue"),
    },
    {
      path: "registration/accept/:token",
      name: "registration.accept",
      component: () =>
        import("@/modules/authorisation/views/RegistrationAccept.vue"),
    },
    {
      path: "magic-link",
      name: "magicLink",
      component: () => import("@/modules/authorisation/views/MagicLink.vue"),
    },
  ],
};

export default authorisationRoutes;
