// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ActionContext } from "vuex";
import {
  Document,
  indexDocuments,
  showDocument,
} from "@/modules/files/api/documents";
import {
  PhotoCategory as PhotoCategoryInterface,
  photos,
} from "@/modules/files/api/photos";
import Vue from "vue";

const initialState = {
  objectionRequests: [],
  checkNotConvertedTimeout: 0,
};

interface State {
  objectionRequests: ObjectionRequestFiles[];
  checkNotConvertedTimeout: number;
}

interface ObjectionRequestFiles {
  id: number;
  photos: PhotoCategoryInterface[];
  documents: Document[];
}

const getObjectionRequestIndex = (state: State, objectionRequestId: number) =>
  state.objectionRequests.findIndex(
    (found: ObjectionRequestFiles) => objectionRequestId === found.id
  );

const getDocumentIndex = (
  state: State,
  objectionRequestId: number,
  documentId: number
) =>
  state.objectionRequests[
    getObjectionRequestIndex(state, objectionRequestId)
  ].documents.findIndex((found: Document) => documentId === found.id);

export default {
  namespaced: true,
  state: initialState as State,
  mutations: {
    setPhotos(
      state: State,
      {
        objectionRequestId,
        photos,
      }: { objectionRequestId: number; photos: PhotoCategoryInterface[] }
    ): void {
      state.objectionRequests[
        getObjectionRequestIndex(state, objectionRequestId)
      ].photos = photos;
    },
    setDocuments(
      state: State,
      {
        objectionRequestId,
        documents,
      }: { objectionRequestId: number; documents: Document[] }
    ): void {
      state.objectionRequests[
        getObjectionRequestIndex(state, objectionRequestId)
      ].documents = documents;
    },
    setDocument(
      state: State,
      {
        document,
        objectionRequestId,
      }: { document: Document; objectionRequestId: number }
    ): void {
      const documentIndex = getDocumentIndex(
        state,
        objectionRequestId,
        document.id
      );
      if (documentIndex !== -1) {
        Vue.set(
          state.objectionRequests[
            getObjectionRequestIndex(state, objectionRequestId)
          ].documents,
          documentIndex,
          document
        );
      }
    },
    deleteDocument(
      state: State,
      {
        documentId,
        objectionRequestId,
      }: { documentId: number; objectionRequestId: number }
    ): void {
      const index = getDocumentIndex(state, objectionRequestId, documentId);
      if (index !== -1) {
        Vue.delete(
          state.objectionRequests[
            getObjectionRequestIndex(state, objectionRequestId)
          ].documents,
          index
        );
      }
    },
    deletePhoto(
      state: State,
      {
        photoId,
        objectionRequestId,
      }: { photoId: number; objectionRequestId: number }
    ): void {
      const objectionRequestIndex = getObjectionRequestIndex(
        state,
        objectionRequestId
      );

      if (objectionRequestIndex === -1) {
        throw "Store:objectionRequestFiles::deletePhoto No objectionRequestId found";
      }

      const photosWithIndexes = state.objectionRequests[
        objectionRequestIndex
      ].photos.flatMap((category) =>
        category.photos.map((photo) => [
          photo.id,
          category.photos.findIndex((found) => found.id === photo.id),
          state.objectionRequests[objectionRequestIndex].photos.findIndex(
            (found) => found.type === category.type
          ),
        ])
      );
      const photoIndexPair = photosWithIndexes.find(
        (found) => found[0] === photoId
      );

      if (!photoIndexPair) {
        throw "Store:objectionRequestFiles::deletePhoto No Photo found to delete";
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, photoIndex, categoryIndex] = photoIndexPair;
      state.objectionRequests[objectionRequestIndex].photos[
        categoryIndex
      ].photos.splice(photoIndex, 1);
      if (
        state.objectionRequests[objectionRequestIndex].photos[categoryIndex]
          .photos.length === 0
      ) {
        state.objectionRequests[objectionRequestIndex].photos.splice(
          categoryIndex,
          1
        );
      }
    },
    addNewObjectionRequest(state: State, objectionRequestId: number): void {
      state.objectionRequests.push({
        id: objectionRequestId,
        photos: [],
        documents: [],
      });
    },
    setCheckNotConvertedTimeout(
      state: State,
      checkNotConvertedTimeout: number
    ): void {
      state.checkNotConvertedTimeout = checkNotConvertedTimeout;
    },
  },
  actions: {
    async fetchFiles(
      context: ActionContext<unknown, unknown>,
      {
        objectionRequestId,
        refetch,
      }: { objectionRequestId: number; refetch?: boolean }
    ): Promise<void> {
      if (
        !refetch &&
        context.getters.objectionRequestFiles(objectionRequestId)
      ) {
        return;
      }
      if (!refetch) {
        context.commit("addNewObjectionRequest", objectionRequestId);
      }

      const promises = [];

      promises.push(
        photos(objectionRequestId).then((response) => {
          context.commit("setPhotos", {
            objectionRequestId,
            photos: response.data.data,
          });
        })
      );

      promises.push(
        indexDocuments(objectionRequestId).then((response) => {
          context.commit("setDocuments", {
            objectionRequestId,
            documents: response.data.data,
          });
        })
      );

      context.dispatch("checkUnconvertedDocuments");
      await Promise.allSettled(promises);
    },
    checkUnconvertedDocuments(context: ActionContext<unknown, unknown>): void {
      const checkConverted = () => {
        context.getters.notConvertedIds.forEach(
          (document: {
            id: number;
            isConverted: boolean;
            objectionRequestId?: number;
          }) => {
            showDocument(document.id).then((response) => {
              context.commit("setDocument", {
                document: response.data.data,
                objectionRequestId: document?.objectionRequestId,
              });
            });
            document.id;
          }
        );
      };
      const initCheckConverted = () => {
        context.commit(
          "setCheckNotConvertedTimeout",
          setTimeout(checkConverted, 5000)
        );
      };
      initCheckConverted();
    },
  },
  getters: {
    objectionRequestFiles:
      (state: State) =>
      (id: number): ObjectionRequestFiles | undefined =>
        state.objectionRequests.find(
          (objectionRequest: ObjectionRequestFiles) =>
            objectionRequest.id === id
        ),
    notConvertedIds: (state: State) => {
      return state.objectionRequests
        .flatMap((objectionRequest) =>
          objectionRequest.documents.map((document) => ({
            ...document,
            objectionRequestId: objectionRequest.id,
          }))
        )
        .map((document) => ({
          id: document.id,
          isConverted: document.isConverted,
          objectionRequestId: document.objectionRequestId,
        }))
        .filter((document) => !document.isConverted);
    },
  },
};
