import eventBus from "@/application/eventBus";
import i18n from "@/plugins/i18n";
import { isAxiosError } from "@/application/util/isAxiosError";
import store from "@/application/store";
import { AxiosError } from "axios";

export default (error: AxiosError | unknown) => {
  if (!isAxiosError(error)) return;
  if (error.message === "Network Error") {
    eventBus.$emit("snackbar", {
      text: i18n.t("global.networkError"),
      color: "error",
    });
    return;
  }

  if (error.response) {
    const { status } = error.response;
    if (status === 401) {
      store.dispatch("authorisation/logout");
      location.reload();
      return;
    }
    if (status === 403) {
      window.location.href = "/forbidden";
      return;
    }
  }

  eventBus.$emit("snackbar", {
    text: i18n.t("global.error"),
    color: "error",
  });
  throw error;
};
