<template>
  <v-dialog v-model="showDialog" width="500px">
    <v-sheet>
      <v-col>
        <v-row class="ma-0">
          <VSpacer />
          <v-btn
            color="grey lighten-2"
            fab
            x-small
            dark
            :elevation="0"
            @click="showDialog = false"
          >
            <v-icon color="black">$close</v-icon>
          </v-btn>
        </v-row>
        <v-row class="ma-3">
          <v-col style="max-width: 80px">
            <v-icon color="primary" :size="50">$warning</v-icon>
          </v-col>
          <v-col>
            <div class="title">
              {{ title }}
            </div>
            <div class="body-1" v-html="body" />
          </v-col>
        </v-row>
        <v-row class="ma-5">
          <VSpacer />
          <v-btn
            v-if="showCancel"
            text
            color="primary"
            @click="showDialog = false"
          >
            {{ $t("actions.cancel") }}
          </v-btn>
          <v-btn color="primary" @click="handleContinue">
            {{ $t("actions.continue") }}
          </v-btn>
        </v-row>
      </v-col>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import EventBus from "@/application/eventBus";
import Vue from "vue";

interface ComponentData {
  title: string;
  body: string;
  showCancel: boolean;
  showDialog: boolean;
  confirmCallback: (() => void) | null;
}

interface ConfirmParams {
  title: string;
  body: string;
  showCancel: boolean;
  confirmCallback: (() => void) | null;
}

export default Vue.extend({
  name: "TheConfirmationDialog",
  data(): ComponentData {
    return {
      showDialog: false,
      confirmCallback: null,
      title: "",
      body: "",
      showCancel: true,
    };
  },
  created() {
    EventBus.$on("confirm", (params: ConfirmParams) => {
      this.title = params.title;
      this.body = params.body;
      this.showCancel = params.showCancel ?? true;
      this.confirmCallback = params.confirmCallback;
      this.showDialog = true;
    });
  },
  methods: {
    handleContinue(): void {
      this.showDialog = false;

      if (typeof this.confirmCallback === "function") {
        this.confirmCallback();
      }
    },
  },
});
</script>
