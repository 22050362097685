import store from "@/application/store/index";
import { NavigationGuard } from "vue-router/types/router";
import { getFragment } from "@/application/util/url";

const RedirectToDashboardIfLoggedInGuard: NavigationGuard = (
  to,
  from,
  next
) => {
  if (store.getters["authorisation/isLoggedIn"]) {
    next({
      path: decodeURIComponent(getFragment("redirect_uri")) || "/dashboard",
    });
    return;
  }
  next();
};
export default RedirectToDashboardIfLoggedInGuard;
