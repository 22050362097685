import dayjs from "@/plugins/dayjs";
import { AxiosResponse } from "axios";

export const getThrottleErrorMinutes = (
  response: AxiosResponse,
  defaultMinutes = 15
): number => {
  if (typeof response.headers["retry-after"] === "undefined") return 0;

  const remainingMilliseconds = +response.headers["retry-after"] * 1000;
  const unlockTime = Date.now() + remainingMilliseconds;

  return dayjs(unlockTime).diff(dayjs(), "minute") || defaultMinutes;
};
