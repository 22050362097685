// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ActionContext } from "vuex";
import { indexTasks, Task } from "@/modules/tasks/api/taskIndex";
import Vue from "vue";

const initialState = {
  objectionRequests: [],
};

interface ObjectionRequestTasks {
  id: number;
  tasks: Task[];
}

interface State {
  objectionRequests: ObjectionRequestTasks[];
}

const getTaskAndObjectionRequestIndexByTaskId = (
  state: State,
  taskId: number
) => {
  let taskIndex = -1;
  const objectionRequestIndex = state.objectionRequests.findIndex(
    (found: ObjectionRequestTasks) => {
      taskIndex = found.tasks.findIndex((task: Task) => task.id === taskId);
      return taskIndex !== -1;
    }
  );
  return { taskIndex, objectionRequestIndex };
};

export default {
  namespaced: true,
  state: initialState as State,
  mutations: {
    setObjectionRequest(
      state: State,
      objectionRequest: ObjectionRequestTasks
    ): void {
      const index = state.objectionRequests.findIndex(
        (found: ObjectionRequestTasks) => objectionRequest.id === found.id
      );
      if (index !== -1) {
        Vue.set(state.objectionRequests, index, objectionRequest);
        return;
      }
      state.objectionRequests.push(objectionRequest);
    },
    completeTask(state: State, taskId: number): void {
      const { taskIndex, objectionRequestIndex } =
        getTaskAndObjectionRequestIndexByTaskId(state, taskId);
      Vue.set(state.objectionRequests[objectionRequestIndex].tasks, taskIndex, {
        ...state.objectionRequests[objectionRequestIndex].tasks[taskIndex],
        isCompleted: true,
      });
    },
    deleteTask(state: State, taskId: number): void {
      const { taskIndex, objectionRequestIndex } =
        getTaskAndObjectionRequestIndexByTaskId(state, taskId);
      Vue.delete(
        state.objectionRequests[objectionRequestIndex].tasks,
        taskIndex
      );
    },
  },
  actions: {
    async fetchObjectionRequestTasks(
      context: ActionContext<unknown, unknown>,
      {
        objectionRequestId,
        refetch,
      }: { objectionRequestId: number; refetch?: boolean }
    ): Promise<void> {
      if (
        !refetch &&
        context.getters.ObjectionRequestTasks(objectionRequestId)
      ) {
        return;
      }
      const result = await indexTasks(objectionRequestId);
      context.commit("setObjectionRequest", {
        id: objectionRequestId,
        tasks: result.data.data,
      });
    },
  },
  getters: {
    ObjectionRequestTasks:
      (state: State) =>
      (id: number): ObjectionRequestTasks | undefined =>
        state.objectionRequests.find(
          (objectionRequest: ObjectionRequestTasks) =>
            objectionRequest.id === id
        ),
  },
};
