// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ActionContext } from "vuex";
import { ObjectionRequest, show } from "@/modules/ObjectionRequest/api/show";

const initialState = {
  objectionRequests: [],
};

interface State {
  objectionRequests: ObjectionRequest[];
}

export default {
  namespaced: true,
  state: initialState as State,
  mutations: {
    setObjectionRequest(
      state: State,
      objectionRequest: ObjectionRequest
    ): void {
      const index = state.objectionRequests.findIndex(
        (found: ObjectionRequest) => objectionRequest.id === found.id
      );
      if (index !== -1) {
        state.objectionRequests[index] = objectionRequest;
        return;
      }
      state.objectionRequests.push(objectionRequest);
    },
  },
  actions: {
    async fetchObjectionRequest(
      context: ActionContext<State, State>,
      {
        objectionRequestId,
        refetch,
      }: { objectionRequestId: number; refetch?: boolean }
    ): Promise<void> {
      if (!refetch && context.getters.objectionRequest(objectionRequestId)) {
        return;
      }
      const result = await show(objectionRequestId);
      context.commit("setObjectionRequest", result.data.data);
    },
  },
  getters: {
    objectionRequest:
      (state: State) =>
      (id: number): ObjectionRequest | undefined =>
        state.objectionRequests.find(
          (objectionRequest: ObjectionRequest) => objectionRequest.id === id
        ),
  },
};
