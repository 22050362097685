export const getBaseUrl = (): string => {
  if (window.location.host === "mijn.bezwaarmaker.nl") {
    return process.env.VUE_APP_PROD_ROOT_API;
  }
  if (window.location.host === "demo.mijn.bezwaarmaker.nl") {
    return process.env.VUE_APP_DEMO_ROOT_API;
  }
  return process.env.VUE_APP_DEV_ROOT_API;
};

export const environment = (): string => {
  if (window.location.host === "mijn.bezwaarmaker.nl") {
    return "production";
  }
  if (window.location.host === "demo.mijn.bezwaarmaker.nl") {
    return "demo";
  }
  return "development";
};
