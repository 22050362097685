import { get } from "@/application/api";
import { AxiosResponse } from "axios";
import { RealEstateType } from "@/application/enum/RealEstateType";
import { LivingSituation } from "@/application/enum/LivingSituation";

export interface ObjectionRequest {
  assessment: Assessment;
  objectionRequestStatus: ObjectionRequestStatus;
  realEstate: RealEstate;
  explanation: string;
  hasDigitalSignature: boolean;
  validatedAt: string;
  id: number;
}

export interface Assessment {
  assessmentDate: string;
  assessmentNumber: string;
  wozValue: number;
  valuationDate: string;
  gender?: string;
  initials: string;
  insertion?: string;
  lastName: string;
}

export interface ObjectionRequestStatus {
  id: number;
  name: string;
  description: string;
  explanation: string;
}

export interface RealEstate {
  zipCode: string;
  houseNumber: string;
  houseNumberAddition?: string;
  street: string;
  city: string;
  realEstateType: RealEstateType;
  livingSituation: LivingSituation;
  heritageYear?: string;
  heritageAcquirer?: string;
  municipality: {
    id: number;
    assessmentNumberLabel: string;
    name: string;
    province?: string;
    requiresWetSignature: boolean;
  };
}

interface showResponse {
  data: ObjectionRequest;
}

export const show = async (
  objectionRequestId: number
): Promise<AxiosResponse<showResponse>> => {
  return await get(`objection-request/${objectionRequestId}`);
};
