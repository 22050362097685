<template>
  <div class="fill-height">
    <TheAuthorisationAppBar />
    <v-main class="fill-height">
      <v-row class="fill-height" no-gutters>
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          sm="4"
          class="background mr-sm-16"
        ></v-col>
        <v-col sm="4" lg="3" class="mt-md-16 ml-sm-16 ma-6 mt-8">
          <h1 class="text-h1 mb-8">Mijn bezwaarmaker</h1>
          <router-view></router-view>
        </v-col>
        <VSpacer v-if="$vuetify.breakpoint.smAndUp" />
      </v-row>
    </v-main>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import TheAuthorisationAppBar from "@/templates/Authorisation/TheAuthorisationAppBar.vue";

export default Vue.extend({
  name: "Authorisation",
  components: { TheAuthorisationAppBar },
});
</script>

<style lang="scss" scoped>
.background {
  background-image: url("~@/assets/images/bg_login.png");
  background-position: center center;
  background-size: cover;
}
@media (min-resolution: 2dppx) {
  .background {
    background-image: url("~@/assets/images/bg_login@2x.png");
  }
}
</style>
