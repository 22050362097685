import { AxiosResponse } from "axios";
import { get } from "@/application/api";
import { TaskType } from "@/application/enum/TaskType";

export interface Task {
  id: number;
  title: string;
  subtitle?: string;
  explanation: string;
  type: TaskType;
  isRequired: boolean;
  isCompleted: boolean;
  entities?: Record<string, string>; // example: {documentType: "assessment"} or {photoType: "kitchen"}
  deadline?: string;
  isUnaccomplished: boolean;
}

interface TaskIndexResponse {
  data: Task[];
}

export const indexTasks = (
  objectionRequestId: number
): Promise<AxiosResponse<TaskIndexResponse>> => {
  return get(`/objection-request/${objectionRequestId}/task`);
};
