import {
  faHome,
  faArrowToBottom,
  faCommentDots,
} from "@fortawesome/pro-solid-svg-icons";
import { faFile } from "@fortawesome/pro-regular-svg-icons";

const icons = [faHome, faFile, faArrowToBottom, faCommentDots];

/*project specific icons*/
const identifiers = {
  dashboard: ["fas", "home"],
  document: ["far", "file"],
  download: ["fas", "arrow-to-bottom"],
  remark: ["fas", "comment-dots"],
};

export default {
  icons,
  identifiers,
};
