<template>
  <v-app-bar
    app
    outlined
    flat
    class="pa-0"
    :height="$vuetify.breakpoint.smAndUp ? '100px' : '60px'"
  >
    <v-row
      justify="center"
      align="center"
      class="app-bar-row no-gutters fill-height px-3"
    >
      <v-col cols="12" sm="6">
        <v-row align="center" justify="start" no-gutters>
          <a href="https://bezwaarmaker.nl">
            <img
              :src="require('@/assets/images/logo.svg')"
              alt="Bezwaarmaker.nl"
              height="40px"
            />
          </a>
        </v-row>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndUp" sm="6">
        <v-row no-gutters align="center" justify="end">
          <span class="body-2">Vragen?</span>
          <v-btn
            text
            color="accent"
            small
            href="tel:0031857730297"
            class="px-1"
          >
            085 773 0297
          </v-btn>
          <div v-if="$vuetify.breakpoint.smAndUp" class="iframe-wrapper">
            <iframe
              id="klantenvertellen"
              class="ml-3"
              loading="lazy"
              frameborder="0"
              allowtransparency="true"
              src="https://www.klantenvertellen.nl/retrieve-widget.html?color=white&amp;button=false&amp;lang=nl&amp;tenantId=99&amp;locationId=1038206"
              width="100"
              height="180"
            ></iframe>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "TheAuthorisationAppBar",
});
</script>

<style scoped lang="scss">
::v-deep {
  .v-toolbar__content {
    justify-content: center;
  }
  .app-bar-row {
    max-width: 1200px;
    margin: 0 auto;
  }

  .iframe-wrapper {
    & iframe {
      margin-top: -13px;
    }

    overflow: hidden;
    height: 90px;
  }
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: white; // i'm not using the vuetify class, because that overwrites the border as well
}
</style>
