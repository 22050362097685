import Vue from "vue";
import Vuex from "vuex";
import authorisation from "@/modules/authorisation/store/authorisation";
import error from "@/application/store/error";
import createPersistedState from "vuex-persistedstate";
import objectionRequest from "@/modules/ObjectionRequest/store/objectionRequest";
import objectionRequestFiles from "@/modules/files/store/objectionRequestFiles";
import objectionRequestTasks from "@/modules/tasks/store/objectionRequestTasks";

Vue.use(Vuex);
const persistedState = createPersistedState({
  paths: ["authorisation"],
});

export default new Vuex.Store({
  plugins: [persistedState],
  modules: {
    authorisation,
    error,
    objectionRequest,
    objectionRequestFiles,
    objectionRequestTasks,
  },
});
