<template>
  <v-btn v-bind="fieldProps" v-on="$listeners">
    <template v-for="(_, name) in $slots" #[name]>
      <slot :name="name" />
    </template>
  </v-btn>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "KBtn",
  computed: {
    fieldProps() {
      return {
        color: typeof this.$attrs.text !== "undefined" ? "black" : "primary",
        large: true,
        depressed: true,
        ...this.$attrs,
      };
    },
  },
});
</script>

<style scoped lang="scss">
::v-deep.v-btn:not(.v-btn--text, .v-btn--outlined) {
  padding-bottom: 3px !important;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 5px solid rgba(0, 0, 0, 0.15) !important;
  }
}
</style>
