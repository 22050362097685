import Vue from "vue";
import Vuetify from "vuetify";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import nl from "vuetify/es5/locale/nl.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import icons from "@/plugins/icons/index.js";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { nl },
    current: "nl",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#11be16",
        accent: "#f86c2f",
        background: "#eef2f3",
      },
    },
  },
  icons,
});
