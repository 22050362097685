import { get, post, destroy } from "@/application/api";
import { AxiosResponse } from "axios";

export interface Document {
  id: number;
  isConverted: boolean;
  name: string;
  type: string;
  url: string;
  remark: string;
}

interface indexResponse {
  data: Document[]; // @TODO without remark
}

interface showResponse {
  data: Document;
}

export const indexDocuments = async (
  objectionRequestId: number
): Promise<AxiosResponse<indexResponse>> => {
  return await get(`objection-request/${objectionRequestId}/document`);
};

export const showDocument = async (
  documentId: number
): Promise<AxiosResponse<showResponse>> => {
  return await get(`document/${documentId}`);
};

interface createRequest {
  objectionRequestId: number;
  type: unknown; //@TODO
  files: number[];
  description: string;
}

export const createDocument = (
  data: createRequest
): Promise<AxiosResponse<void>> => {
  return post(`objection-request/${data.objectionRequestId}/document`, {
    type: data.type,
    files: data.files,
    description: data.description,
  });
};

export const deleteDocument = (
  documentId: number
): Promise<AxiosResponse<void>> => {
  return destroy(`document/${documentId}`);
};
