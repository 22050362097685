<template>
  <v-app>
    <router-view />
    <TheSnackbarQueueHandler />
    <TheConfirmationDialog />
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TheSnackbarQueueHandler from "@/components/TheSnackbarQueueHandler.vue";
import TheConfirmationDialog from "@/components/TheConfirmationDialog.vue";

export default Vue.extend({
  name: "App",
  components: { TheConfirmationDialog, TheSnackbarQueueHandler },
  computed: {
    ...mapGetters({
      isLoggedIn: "authorisation/isLoggedIn",
    }),
  },
  mounted() {
    if (this.isLoggedIn) {
      this.fetchProfile();
    }
    this.$router.beforeEach((to, from, next) => {
      this.errorClear();
      next();
    });
  },
  methods: {
    ...mapActions({
      fetchProfile: "authorisation/fetchProfile",
    }),
    ...mapMutations({
      errorClear: "error/clear",
    }),
  },
});
</script>
