import {
  onRequestFulFilled,
  onRequestRejected,
  onResponseFulFilled,
  onResponseRejected,
} from "@/application/api/axiosInterceptor";
import {
  transformParams,
  transformRequest,
  transformResponse,
} from "@/application/api/dataTransformer";
import axios, { AxiosRequestConfig, AxiosRequestTransformer } from "axios";
import { getBaseUrl } from "@/application/api/baseUrl";

/**
 * Returns an axios instance
 * specifically made for app API calls.
 */

const config: AxiosRequestConfig = {
  baseURL: getBaseUrl(),
  headers: {
    Accept: "application/json",
  },
  transformRequest: [
    transformRequest,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  transformResponse: [transformResponse],
  paramsSerializer: {
    serialize: transformParams,
  },
};

const instance = axios.create(config);

instance.interceptors.request.use(onRequestFulFilled, onRequestRejected);
instance.interceptors.response.use(onResponseFulFilled, onResponseRejected);

export const { get } = instance;
export const { post } = instance;
export const destroy = instance.delete;
export const { put } = instance;
