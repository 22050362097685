import RedirectToLoginIfUnauthorisedGuard from "@/router/guards/RedirectToLoginIfUnauthorisedGuard";
import { RouteConfig } from "vue-router";

const authorisedRoutes: RouteConfig = {
  path: "",
  beforeEnter: RedirectToLoginIfUnauthorisedGuard,
  component: () => import("@/templates/Authorised/Authorised.vue"),
  children: [
    {
      path: "/dashboard",
      name: "dashboard",
      meta: {
        cardLayout: true,
      },
      component: () => import("@/modules/dashboard/views/Dashboard.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      meta: {
        cardLayout: true,
      },
      component: () => import("@/modules/authorisation/views/Logout.vue"),
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/modules/profile/views/Profile.vue"),
    },
    {
      path: "/profile/email/verify",
      name: "profile.email.verify",
      component: () => import("@/modules/profile/views/EmailVerify.vue"),
    },
    {
      path: "/objection-request/create",
      name: "objection-request.create",
      component: () =>
        import("@/modules/ObjectionRequest/views/CreateForm.vue"),
    },
    {
      path: "/objection-request/:objectionRequestId",
      name: "objection-request.show",
      meta: {
        cardLayout: true,
      },
      component: () => import("@/modules/ObjectionRequest/views/Show.vue"),
    },
    {
      path: "/objection-request/:objectionRequestId/task/:taskId",
      name: "objection-request.task.show",
      meta: {
        cardLayout: true,
      },
      component: () => import("@/modules/ObjectionRequest/views/Show.vue"),
    },
    {
      path: "/objection-request/:objectionRequestId/self-proceed",
      name: "objection-request.task.show",
      meta: {
        cardLayout: true,
      },
      component: () =>
        import("@/modules/ObjectionRequest/views/SelfProceed.vue"),
    },
    {
      path: "/objection-request/:objectionRequestId/files",
      name: "objection-request.files",
      component: () => import("@/modules/files/views/Files.vue"),
    },
    {
      path: "/objection-request/:objectionRequestId/info",
      name: "objection-request.info",
      component: () =>
        import("@/modules/ObjectionRequest/views/UpdateForm.vue"),
    },
    {
      path: "/objection-request/:objectionRequestId/validate",
      name: "objection-request.validate",
      component: () => import("@/modules/ObjectionRequest/views/Validate.vue"),
    },
    {
      path: "/objection-request/:objectionRequestId/questionnaire",
      name: "objection-request.questionnaire",
      component: () =>
        import("@/modules/questionnaire/views/QuestionnaireForm.vue"),
      meta: {
        cardLayout: true,
      },
    },
    {
      path: "forbidden",
      name: "forbidden",
      component: () => import("@/modules/error/views/Forbidden.vue"),
    },
    {
      path: "*",
      name: "not-found",
      component: () => import("@/modules/error/views/NotFound.vue"),
    },
  ],
};

export default authorisedRoutes;
