import global from "./global.json";
import authorisation from "./authorisation.json";
import actions from "./actions.json";
import objectionRequestAccept from "./objectionRequestAccept.json";
import objectionSignatureRequest from "./objectionSignatureRequest.json";
import objectionRequest from "./objectionRequest.json";
import files from "./files.json";
import navigation from "./navigation.json";
import questionnaire from "./questionnaire.json";
import validation from "./validation.json";
import task from "./task.json";
import dashboard from "./dashboard.json";
import profile from "./profile.json";

export default {
  global,
  authorisation,
  actions,
  objectionRequestAccept,
  objectionSignatureRequest,
  objectionRequest,
  files,
  navigation,
  questionnaire,
  validation,
  task,
  dashboard,
  profile,
};
